<template>
  <div class="el-input el-input--mini">
    <input
      v-bind="$attrs"
      :value="value"
      type="text"
      autocomplete="off"
      class="el-input__inner"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)">
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  }
}
</script>
