<template>
  <div>
    <div  class="length-text">
      <span>Показано {{ list.length }} {{ getSpell(list.length)}} из {{ query.total }}</span>
    </div>
    <el-table
      v-if="showTable"
      ref="table"
      v-loading="loading"
      :data="list"
      border
      fit
      highlight-current-row
      size="mini"
      class="main-table"
      :header-cell-class-name="headerClass"
      :row-class-name="rowClassName"
      @header-click="handleHeaderClick"
      @header-dragend="handleWidth"
      @row-dblclick="handleDblClick">
      <el-table-column
        v-if="needId"
        label="ID"
        align="center"
        :prop="keyField"
        label-class-name="order">
        <el-table-column
          align="center"
          :prop="keyField"
          :sort-orders="[]"
          width="80px">
          <template slot="header">
            <v-input
              v-model="query[keyField]"
              @change="fetchData(true)" />
          </template>
          <router-link
            slot-scope="scope"
            :to="detailRoute(scope.row)"
            class="link-type">
            {{ scope.row[keyField] }}
          </router-link>
        </el-table-column>
      </el-table-column>
      <el-table-column
        v-for="column in completeColumns"
        :key="column.field"
        :label="labels[column.field]"
        :prop="column.field"
        :align="column.align || 'center'"
        label-class-name="order">
        <el-table-column
          :align="column.align || 'left'"
          :prop="column.field"
          :width="column.width"
          :sort-orders="[]">
          <template slot="header"
                    v-if="column.filterable !== false">
            <el-select
              v-if="column.items"
              v-model="query[column.field]"
              size="mini"
              filterable
              clearable
              style="width: 100%"
              :multiple="column.multiple"
              @change="fetchData(true)">
              <el-option
                v-for="(item) in column.items"
                :key="item.id"
                :label="item.title"
                :value="item.id" />
            </el-select>
            <v-input
              v-else-if="renderFilters"
              v-model="query[column.field]"
              @change="fetchData(true)" />
          </template>
          <template slot-scope="scope">
            <el-checkbox
              v-if="column.type === 'checkbox'"
              v-model="scope.row[column.field]"
              @change="value => $emit(`${column.field}-checked`, scope.row, value)" />
            <el-tag
              v-else-if="column.type === 'tag'"
              :type="column.resolveType(scope.row)">
              {{ getValue(scope.row, column) }}
            </el-tag>
            <katex-element v-if="column.type === 'formula' && scope.row[column.field].indexOf('`') >= 0" :expression="getFormula(scope.row[column.field])"/>
            <template v-else>
              {{ getValue(scope.row, column) }}
            </template>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>

    <pagination
      v-show="query.total>0"
      :auto-scroll="autoScroll"
      :limit.sync="query.per_page"
      :page.sync="query.current_page"
      :total="query.total"
      @pagination="fetchData()" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import VInput from '@/components/VInput'
import AsciiMathParser from 'asciimath2tex'

export default {
  components: { Pagination, VInput },
  props: {
    keyField: {
      type: String,
      default: 'id'
    },
    name: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    rowClassName: {
      type: Function,
      default: () => {
        return ''
      }
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    needId: {
      type: Boolean,
      default: true
    },
    renderFilters: {
      type: Boolean,
      default: true
    },
    detailRoute: {
      type: Function,
      default (params) {
        return { name: this.$route.name.replace('list', 'view'), params }
      }
    },
    fetch: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      list: [],
      loading: true,
      query: {
        total: 0,
        filter: undefined,
        current_page: 1,
        per_page: 20,
        sort_column: undefined,
        sort_direction: undefined
      },
      filters: [],
      labels: {},
      showTable: true,
      showFilters: false
    }
  },
  watch: {
    columns: {
      handler () {
        this.showTable = false
        this.$nextTick(() => {
          this.showTable = true
        })
      }
    }
  },
  computed: {
    completeColumns () {
      const result = this.columns.slice()
      const ordered = Object.keys(this.labels)
      // Фильтруем уже имеющиеся поля с фильтрами
      for (const field of ordered.filter(x => this.columns.findIndex(y => y.field === x) === -1)) {
        result.push({ field })
      }
      return result.sort((a, b) => ordered.indexOf(a.field) - ordered.indexOf(b.field))
    },
    labelList () {
      const result = []
      for (const key in this.labels) {
        result.push({
          key,
          label: this.labels[key]
        })
      }
      return result
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    getSpell (val) {
      if (val % 100 < 20 && val % 100 > 10) {
        return 'записей'
      }
      if (val % 10 === 1) {
        return 'запись'
      } else if (val % 10 < 5 && val % 10 > 1) {
        return 'записи'
      }
      return 'записей'
    },
    getFormula (val) {
      if (val.indexOf('`') >= 0) {
        val = val.replaceAll('`', '')
        const parser = new AsciiMathParser()
        return parser.parse(val).replaceAll('∑', 'Σ')
      }
      return val
    },
    getValue (row, column) {
      if (column.filter) {
        return column.filter(row[column.field])
      }
      return row[column.field]
    },
    handleWidth (newWidth, oldWidth, column) {
      // this.options.width[column.property] = newWidth
    },
    handleDblClick (row) {
      this.$router.push(this.detailRoute(row))
    },
    fetchData (toFirstPage = false) {
      this.loading = true
      if (toFirstPage) {
        this.query.current_page = 1
      }
      this.query.filter = this.renderFilters
      return this.fetch(this.query).then(({ data }) => {
        this.list = data.data
        this.query.total = data.meta.total
        this.labels = data.meta.labels
        if (!this.labels) {
          console.warn('labels is undefined')
          this.labels = {}
        }
        // force re-render for select in filter (status for example)
        this.showTable = false
        this.$nextTick(() => {
          this.showTable = true
        })
      }).finally(() => {
        this.loading = false
      })
    },
    handleHeaderClick (col) {
      if (col.sortOrders.length) {
        if (col.property === this.query.sort_column) {
          if (this.query.sort_direction === 'asc') {
            this.query.sort_direction = 'desc'
          } else {
            this.query.sort_column = undefined
            this.query.sort_direction = undefined
          }
        } else {
          this.query.sort_column = col.property
          this.query.sort_direction = 'asc'
        }

        this.fetchData()
      }
    },
    headerClass (data) {
      if (data.column.property && data.column.property === this.query.sort_column) {
        return this.query.sort_direction
      }
    }
  }
}
</script>

<style scoped>

.main-table >>> .el-table__header-wrapper {
  /* need for sticky header */
  top: 50px;
  z-index: 1;
}

.el-table >>> .cell {
  line-height: 17px;
  font-size: 14px;
}

.el-table >>> .el-input__suffix {
  right: 10px;
}

.el-table >>> thead > tr:nth-child(2) > th > div.cell {
  line-height: 0;
  padding: 0 10px;
}
.buttons > * + * {
  margin-left: 10px;
}
.el-table >>> .katex {
  font-size: 20px;
}
</style>
